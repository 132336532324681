<template>
  <div>
    <h1>Feedback</h1>
    <p style="text-align: left;">Hast Du einen Fehler gefunden? Würdest Du etwas anders machen? Hast Du eine Idee / einen Wunsch, wie dieses Angebot besser werden kann?</p>
    <p style="text-align: left;">Wünsche / Anmerkungen / Hinweise auf Fehler sind jederzeit willkommen. Nutze dafür bitte das Formular auf dieser Seite: <a :href="report" target="_blank">Feedback-Formular</a></p>
  </div>
</template>

<script>

import Helper from "../model/helper";

export default {
  name: 'FeedbackView',
  async setup() {
    let config = await Helper.loadJson('config.json')
    return {
      report: config.report
    }
  }
}
</script>
