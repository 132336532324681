import Part from './part'
import Helper from './helper'

export default class Lesson {
    constructor(lessonName, lessonJson) {
        this.name = lessonName
        this.lessonJson = lessonJson
        this.title = lessonJson.title
        this.titleShort = lessonJson.titleShort || lessonJson.title
        this.description = lessonJson.description
        this.category = lessonJson.category
        this.to = `/thema/${this.name}`
        this.parts = null
        this.partsMap = null
    }

    static async create(lessonName) {
        const lessonJson = await Helper.loadJson(`lessons/${lessonName}/lesson.json`)
        return new Lesson(lessonName, lessonJson);
    }

    async initParts() {
        if (this.parts !== null && this.partsMap !== null) {
            return
        }

        let parts = []
        let partsMap = {}

        let idx = -1
        for (let partName of this.lessonJson.parts) {
            partsMap[partName] = await Part.create(this, partName)
            parts.push(partsMap[partName])

            if (idx >= 0) {
                partsMap[partName].prev = parts[idx]
                parts[idx].next = partsMap[partName]
            }
            idx += 1
        }

        this.parts = parts
        this.partsMap = partsMap
    }


    async getParts() {
        await this.initParts()
        return this.parts
    }

    async getPart(partName) {
        await this.initParts()
        return this.partsMap[partName]
    }
}
