<template>
  <div class="memory">
    <div :class="'mcard ' + memory.color + (card.flipped ? ' flipped' : '') + (!card.enabled ? ' correct' : '')" v-for="(card, index) in cards" :key="card.id" v-on:click="cardClicked(index)">
      <span class="front" v-if="card.type === 'Text'" v-html="card.content"></span>
      <img class="front" v-if="card.type === 'Image'" :src="card.content" />
    </div>

    <span v-if="!memory.active" v-on:click="restartClicked" :class="memory.color + ' btn main-sized no-print'">Neustart</span>
  </div>
</template>

<style scoped>
  .memory {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .memory .btn {
    position: fixed;
    bottom: 0;
    padding: 0.5em;
    margin: auto;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: bold;

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }

  .mcard {
    width: 150px;
    height: 150px;
    padding: 0.5em;
    margin: 0.5em;
    flex: 0 1 auto;

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;

    border: 1px solid #555;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .front {
    max-height: 100%;
    max-width: 100%;
  }

  .mcard:not(.flipped) .front {
    display: none;
  }

  .mcard.flipped .front {
    display: inline-block;
  }

  .mcard.flipped {
    background-color: #fff !important;
    color: #2c3e50 !important;
  }

  .mcard:not(.flipped) {
    background:
    linear-gradient(115deg, transparent 75%, rgba(255,255,255,.5) 75%) 0 0,
    linear-gradient(245deg, transparent 75%, rgba(255,255,255,.5) 75%) 0 0,
    linear-gradient(115deg, transparent 75%, rgba(255,255,255,.5) 75%) 7px -15px,
    linear-gradient(245deg, transparent 75%, rgba(255,255,255,.5) 75%) 7px -15px,
    #36c;
    background-size: 15px 30px;
  }

  .mcard:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .mcard.flipped.correct {
    -webkit-animation-name: flash-animation;
    -webkit-animation-duration: 1s;

    animation-name: flash-animation;
    animation-duration: 1s;
  }

  @-webkit-keyframes flash-animation {
    from { background: green; }
    to   { background: default; }
  }

  @keyframes flash-animation {
    from { background: green; }
    to   { background: default; }
  }
</style>

<script>
  import {reactive} from "vue";

  async function fetchData (part) {
    let memory = await part.getMemory()
    return reactive({
      memory,
      cards: memory.cards
    })
  }

  export default {
    name: 'MemoryPart',
    props: ['part'],
    methods: {
      cardClicked (idx) {
        this.memory.flipCard(idx)
      },
      restartClicked () {
        this.memory.restart()
        this.cards = this.memory.cards
      }
    },
    async setup (props) {
      return await fetchData(props.part)
    }
  }
</script>
