<template>
  <div class="card align-left" v-bind:class="{ greenglow: !question.quiz.active && question.correct, redglow: !question.quiz.active && !question.correct }">
    <p v-html="question.title"></p>
    <component v-bind:is="question.type + 'Type'" :question="question"></component>
    <p class="subtitle" v-if="question.subtitle" v-html="question.subtitle"></p>
  </div>
</template>

<style scoped>

</style>

<script>
  export default {
    name: 'QuestionView',
    props: ['question']
  }
</script>
