<template>
  <div v-if="error">
    {{ error }}
  </div>
  <Suspense v-else>
    <template #default>
      <QuizSlideView :lessonName="lessonName" :partName="partName" />
    </template>
    <template #fallback>
      <div>Lädt...</div>
    </template>
  </Suspense>
</template>

<script>
import {onErrorCaptured, ref} from 'vue'

export default {
  name: 'QuizSlideViewSuspense',
  props: ['lessonName', 'partName'],
  setup() {
    const error = ref(null)
    onErrorCaptured(e => {
      error.value = e
      return true
    })
    return {error}
  }
}
</script>
