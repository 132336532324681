<template>
  <div class="no-print">
    <span class="title menu"><a href="/">{{ title }}</a></span><!-- <span class="dot">·</span> -->
    <ul class="menu">
      <li v-for="(link, index) in links" :key="index">
        <router-link :to="link.to">{{ link.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Helper from "../model/helper";

export default {
  name: 'AppHeader',
  async setup() {
    let config = await Helper.loadJson('config.json')
    return {
      title: config.title,
      links: [
        {
          name: "Feedback",
          to: '/feedback'
        }
        // {
        //   name: 'Einheiten',
        //   to: '/'
        // },
        // {
        //   name: 'Hilfe',
        //   to: '/help'
        // }
      ]
    }
  }
}
</script>
