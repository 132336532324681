import Helper from './helper'

const PAIRS_PER_GAME = 10

export default class Memory {
  constructor (data, color) {
    this.pairs = data.pairs
    this.lastFlipped = []
    this.cards = []
    this.color = color
    this.restart()
  }

  static async create(part) {
    const data = await part.getData()
    return new Memory(data, part.lesson.color)
  }

  flipCard (idx) {
    if (this.cards[idx].flipped) {
      return
    }

    if (this.lastFlipped.length >= 2) {
      // unflipp lastFlipped cards
      for (let idx of this.lastFlipped) {
        this.cards[idx].flipped = false
        this.flipped -= 1
      }
      this.lastFlipped = []
    }

    this.cards[idx].flipped = true

    this.flipped += 1
    this.lastFlipped.push(idx)

    this.check()

    if (this.flipped === this.cards.length) {
      this.active = false
    }
  }

  check () {
    if (this.lastFlipped.length < 2) {
      return
    }

    if (this.cards[this.lastFlipped[0]].pairNr === this.cards[this.lastFlipped[1]].pairNr) {
      this.cards[this.lastFlipped[0]].enabled = false
      this.cards[this.lastFlipped[1]].enabled = false
      this.lastFlipped = []
    }
  }

  restart () {
    let pairs = Helper.shuffle(this.pairs)
    this.cards = this.cards.slice(this.cards.length)
    for (let i = 0; i < PAIRS_PER_GAME && i < pairs.length; i++) {
      this.cards.push({
        id: i + 'a',
        pairNr: i,
        content: pairs[i].a.content,
        type: pairs[i].a.type,
        flipped: false,
        enabled: true
      })
      this.cards.push({
        id: i + 'b',
        pairNr: i,
        content: pairs[i].b.content,
        type: pairs[i].b.type,
        flipped: false,
        enabled: true
      })
    }

    this.cards = Helper.shuffle(this.cards)
    this.flipped = 0
    this.active = true
    this.lastFlipped = []
  }
}
