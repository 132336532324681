import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      title: 'LernApp'
    }
  },
  {
    path: '/thema/:lessonName',
    name: 'Lesson',
    component: () => import('../views/LessonSuspense'),
    props: true,
    meta: {
      title: 'LernApp'
    }
  },
  {
    path: '/thema/:lessonName/:partName/:type',
    name: 'LessonPart',
    component: () => import('../views/LessonPartSuspense'),
    props: true,
    meta: {
      title: 'LernApp'
    }
  },
  {
    path: '/slides/:lessonName/:partName',
    name: 'QuizSlideView',
    component: () => import('../views/QuizSlideViewSuspense'),
    props: true,
    meta: {
      title: 'LernApp'
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/FeedbackSuspense')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
