<template>
  <div class="quiz">
    <p class="no-print" v-if="!quiz.active">{{quiz.correctCount}} von {{quiz.questions.length}} Fragen richtig ({{quiz.result}}%).
      <span v-if="quiz.passed && quiz.result >= quiz.passed">Bestanden!</span>
      <span v-if="quiz.passed && quiz.result < quiz.passed">Nicht bestanden!</span>
    </p>
    <p class="no-print" v-if="quiz.active">{{quiz.questions.length}} Fragen</p>
    <QuestionView v-for="question in quiz.questions" :key="'q' + question.idx" :question="question"></QuestionView>

    <span v-if="quiz.active" v-on:click="checkClicked" :class="quiz.color + ' btn main-sized no-print'">Auswerten</span>
    <span v-if="!quiz.active" v-on:click="restartClicked" :class="quiz.color + ' btn main-sized no-print'">Neustart</span>
  </div>
</template>

<style>
  .quiz .card > p {
    padding-left: 2em;
  }

  .quiz .card.greenglow {
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.3);
  }

  .quiz .card.redglow {
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.3);
  }

  .quiz .card > p::before {
    content: ' ';
    margin-right: 0.2em;
    margin-left: -1.2em;
    width: 1em;
    display: inline-block;
    text-align: center;
  }

  .quiz .card.greenglow > p::before {
    content: '✓';
    color: green;
  }

  .quiz .card.redglow > p:first-child::before {
    content: '✗';
    color: red;
  }

  .quiz .btn {
    position: fixed;
    bottom: 0;
    padding: 0.5em;
    margin: auto;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: bold;

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }

  .quiz .correct {
    color: green;
  }

  .quiz .wrong {
    text-decoration: line-through;
  }

  @media print {
    .quiz {
      counter-reset: section;
    }

    .quiz .card {
      border: none;
    }

    .quiz .card > p:first-child::before {
      counter-increment: section;
      content: counter(section) ".";
    }

    .quiz .card.greenglow, .quiz .card.redglow {
      box-shadow: none;
    }

    .quiz .card.greenglow > p:first-child::before, .quiz .card.redglow > p:first-child::before {
      content: counter(section) ".";
      color: black;
    }

    .quiz .correct, .quiz .wrong {
      color: black;
    }
  }

</style>

<script>
  import {reactive} from "vue";

  export default {
    name: 'QuizPart',
    props: ['part'],
    methods: {
      checkClicked () {
        this.quiz.check()
        this.scrollToTop()
      },
      restartClicked () {
        this.quiz.restart()
        this.scrollToTop()
      },
      scrollToTop () {
        window.scrollTo(0, 0)
      }
    },
    async setup (props) {
      return {
        quiz: reactive(await props.part.getQuiz())
      }
    }
  }
</script>
