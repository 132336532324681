<template>
  <div>
    <BreadCrumb/>
    <h1>Themen</h1>
    <div class="lessons">
      <div v-if="error">
        {{ error }}
      </div>
      <Suspense v-else>
        <template #default>
          <LessonsMenu/>
        </template>
        <template #fallback>
          <div>Lädt...</div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
import {onErrorCaptured, ref} from 'vue'

export default {
  name: 'HomeView',
  setup() {
    const error = ref(null)
    onErrorCaptured(e => {
      error.value = e
      return true
    })
    return {error}
  }
}
</script>
