import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Markdown from 'vue3-markdown-it';

import BreadCrumb from "./components/BreadCrumb";

import AppHeader from "./components/AppHeader";

import FeedbackView from "./views/FeedbackView";

import LessonsMenu from "./views/HomeMenu";

import LessonView from "./views/LessonView";
import LessonSuspense from "./views/LessonSuspense";

import LessonPart from "./views/LessonPart";
import LessonPartSuspense from "./views/LessonPartSuspense";

import QuizSlideView from "./views/QuizSlideView";
import QuizSlideViewSuspense from "./views/QuizSlideViewSuspense";

import QuizPart from './views/part/QuizPart'
import FlashCardPart from './views/part/FlashCardPart'
import TextPart from './views/part/TextPart'
import MemoryPart from './views/part/MemoryPart'

import QuestionView from './views/part/quiz/QuestionView'
import MCType from './views/part/quiz/MCType'

createApp(App)
    .use(router)
    .use(Markdown)
    .component("BreadCrumb", BreadCrumb)

    .component("AppHeader", AppHeader)

    .component("FeedbackView", FeedbackView)

    .component("LessonsMenu", LessonsMenu)

    .component("LessonView", LessonView)
    .component("LessonSuspense", LessonSuspense)

    .component("LessonPart", LessonPart)
    .component("LessonPartSuspense", LessonPartSuspense)

    .component("QuizSlideView", QuizSlideView)
    .component("QuizSlideViewSuspense", QuizSlideViewSuspense)

    .component("QuizPart", QuizPart)
    .component("FlashCardPart", FlashCardPart)
    .component("TextPart", TextPart)
    .component("MemoryPart", MemoryPart)
    .component("QuestionView", QuestionView)
    .component("MCType", MCType)

    .mount('#app')
