import Helper from './helper'
import Question from './question'

export default class Quiz {
  constructor (data, color) {
    let shuffleAnswers = data.shuffleAnswers
    this.questions = Helper.shuffle(data.questions.map((data, idx) => new Question(this, data, idx, shuffleAnswers)))
    this.passed = data.passed
    this.active = true
    this.correctCount = 0
    this.color = color
    this.result = 0
    this.resultEncoded = null
  }

  static async create(part) {
    const data = await part.getData()
    return new Quiz(data, part.lesson.color)
  }

  check() {
    this.active = false
    this.correctCount = 0
    this.resultEncoded = new Array(this.questions.length)
    for (let question of this.questions) {
      let result = question.check()
      if (result.correct) {
        this.correctCount++
        this.resultEncoded[question.idx] = [true]
      } else {
        this.resultEncoded[question.idx] = question.encode()
        this.resultEncoded[question.idx].unshift(result.correct)
      }
    }
    this.result = Math.round((this.correctCount / this.questions.length) * 100)
    this.resultEncoded = this.resultEncoded.map(r => r.map(a => a ? '1' : '0').reduce((a, b) => a + b)).reduce((a, b) => a + b)
    console.log('#######', this.resultEncoded, this.resultEncoded.length)
    this.resultEncoded = parseInt(this.resultEncoded, 2)
    console.log('#######', this.resultEncoded)
    this.resultEncoded = (+this.resultEncoded).toString(32)
    console.log('#######', this.resultEncoded)

    this.resultEncoded = parseInt(this.resultEncoded, 32)
    console.log('#######', this.resultEncoded)
    this.resultEncoded = (+this.resultEncoded).toString(2)
    console.log('#######', this.resultEncoded)
  }

  restart() {
    this.questions = Helper.shuffle(this.questions)
    for (let question of this.questions) {
      question.restart()
    }
    this.active = true
  }
}
