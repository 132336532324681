import {fetch} from 'whatwg-fetch'
import 'js-yaml'
import jsYaml from 'js-yaml';

const staticPath = '../../static';

export default class Helper {
  static shuffle (array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      let temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  static async loadJson(file) {
    try {
      return (await fetch(`${staticPath}/${file}`)).json()
    } catch (e) {
      return null
    }
  }

  static async loadYaml(file) {
    try {
      const text = await (await fetch(`${staticPath}/${file}`)).text()
      if (!text) {
        return null
      }
      return await jsYaml.load(text)
    } catch (e) {
      return null
    }
  }

  static async loadText(file) {
    return (await fetch(`${staticPath}/${file}`)).text()
  }
}
