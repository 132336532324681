<template>
  <div class="reveal-wrapper">
    <div class="reveal">
      <div :class="color + ' bar'"></div>
      <div class="slides">
        <section v-for="(question, qIndex) in questions" v-bind:key="`q_${qIndex}`"
                 :class="question.longText ? 'longText' : ''">
          <section>
            <p v-html="question.title" class="title"></p>
            <ol v-if="question.type !== 'Text'">
              <li v-for="(answer, aIndex) in question.answers" v-bind:key="`a1_${aIndex}`" v-html="answer.title"></li>
            </ol>
            <p v-if="question.subtitle" v-html="question.subtitle" class="subtitle"></p>
          </section>
          <section>
            <p v-html="question.title" class="title"></p>
            <ol v-if="question.type !== 'Text'">
              <li v-for="(answer, aIndex) in question.answers" v-bind:key="`a2_${aIndex}`" v-html="answer.title"
                  :class="answer.correct ? 'correct' : ''"></li>
            </ol>
            <p v-if="question.type === 'Text'" v-html="answer" class="correct"></p>
            <p v-if="question.subtitle" v-html="question.subtitle" class="subtitle"></p>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>

/*@import '../../node_modules/reveal.js/dist/reveal.css';*/
/*@import '../../node_modules/reveal.js/dist/theme/white.css';*/

.reveal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.5em;
}

.title, .subtitle {
  font-weight: bold;
  text-align: left;
}

section {
  text-align: left;
}

.longText {
  font-size: 0.8em;
}

.correct {
  color: green;
}

.correct::before {
  content: '✓';
  color: green;
  margin-left: -2.3em;
  padding-right: 1.5em;
}
</style>

<script>
import {getInstance as getLessonInstance} from '../model/lessons'

import Reveal from 'reveal.js'

async function fetchData(lessonName, partName) {
  const lesson = (await getLessonInstance()).getLesson(lessonName)
  const part = await lesson.getPart(partName)

  import('../../node_modules/reveal.js/dist/reveal.css')
  import('../../node_modules/reveal.js/dist/theme/white.css')

  return {
    questions: (await part.getSlides()).questions,
    color: lesson.color
  }
}

export default {
  name: 'QuizSlideView',
  props: ['lessonName', 'partName'],
  mounted() {
    Reveal.initialize()
  },
  async setup(props) {
    return await fetchData(props.lessonName, props.partName)
  }
}
</script>
