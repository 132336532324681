<template>
  <ul>
    <li v-for="(answer) in question.answers" :key="answer.id">
      <input type="checkbox" :id="answer.id" v-model="answer.checked" :disabled="!question.quiz.active" v-bind:class="{ correct: !question.quiz.active && answer.correct, wrong: !question.quiz.active && !answer.correct }">
      <label :for="answer.id" v-bind:class="{ correct: !question.quiz.active && answer.correct, wrong: !question.quiz.active && !answer.correct }">{{answer.title}}</label>
    </li>
  </ul>
</template>

<style scoped>
  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }

  li:nth-child(odd) {
    background: #ededed;
  }

  li:hover {
    background: rgba(190, 210, 255, 0.49);
  }

  input {
    width: 2em;
    margin-left: 0.5em;
    margin-right: 0;
  }

  label {
    display: inline-block;
    width: calc(100% - 3em);
    vertical-align: top;
  }

  @media print {

    input::before, input.correct::after {
      position: relative;
      top: -0.3em;
      color: black;
      font-weight: bold;
      font-size: 1.5em;
      visibility: visible;
    }

    input.correct::after {
      content: '✗';
      left: -0.9em;
    }


    input::before {
      content: '▢';
    }

    input {
      visibility: hidden;
    }
  }
</style>

<script>
  export default {
    name: 'MCType',
    props: ['question']
  }
</script>
