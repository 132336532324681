<template>
  <div>
    <BreadCrumb :lesson="lesson" />
    <h1>{{ lesson.title }}</h1>
    <p>{{ lesson.description }}</p>
    <div class="parts">
      <router-link class="card hover" v-for="(part, index) in lesson.parts" :key="index" :to="part.to">
          <p :class="part.color ? part.color : lesson.color">{{ part.title }}</p>
          <p>{{ part.description }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
  import {getInstance as getLessonInstance} from '../model/lessons'

  export default {
    name: 'LessonView',
    props: ['lessonName'],
    async setup (props) {
      let lesson = (await getLessonInstance()).getLesson(props.lessonName)
      await lesson.initParts()
      return {
        lesson: lesson
      }
    }
  }
</script>
