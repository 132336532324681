<template>
  <div class="breadcrumb no-print">
    Du bist hier: <span v-for="url in urls" v-bind:key="url.title" class="crumb"><router-link :to="url.to">{{ url.title }}</router-link></span>
  </div>
</template>

<style scoped>
  .breadcrumb {
    padding: 0.5em;
    text-align: center;
  }

  .crumb {
    display: inline-block;
  }

  .crumb a {
    color: inherit;
    text-decoration: underline;
  }

  .crumb:after {
    content: '/';
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .crumb:last-child:after {
    content: '';
  }
</style>

<script>
  export default {
    name: 'BreadCrumb',
    props: ['lesson', 'part'],
    methods: {
      createData () {
        let urls = []
        let last = {
          to: '/',
          title: 'Themen'
        }
        urls.push(last)

        if (this.lesson) {
          last = {
            to: this.lesson.to,
            title: this.lesson.titleShort
          }
          urls.push(last)

          if (this.part) {
            last = {
              to: this.part.to,
              title: this.part.titleShort
            }
            urls.push(last)
          }
        }

        return {
          urls
        }
      }
    },
    data () {
      return this.createData()
    }
  }
</script>
