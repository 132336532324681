<template>
  <Markdown class="align-left" :source="content" :html="true"/>
</template>

<script>
  import Markdown from 'vue3-markdown-it';

  async function fetchData(part) {
    return {
      content: await part.getContent()
    }
  }

  export default {
    name: 'TextPart',
    props: ['part'],
    components: {
      Markdown
    },
    async setup (props) {
      return await fetchData(props.part)
    }
  }
</script>
