<template>
  <div class="flashcard">

    <div class="card align-left" v-bind:class="{ 'notFlipped': !flipped }">
      <p v-html="quiz.questions[index].title"></p>
      <div v-if="flipped" v-html="quiz.questions[index].getSolutionText()" class="solution"></div>
      <p class="subtitle"  v-if="quiz.questions[index].subtitle" v-html="quiz.questions[index].subtitle"></p>
    </div>

    <span v-if="!flipped" v-on:click="flipClicked" :class="quiz.color + ' btn main-sized no-print'">Lösung</span>
    <span v-if="flipped" v-on:click="nextClicked" :class="quiz.color + ' btn main-sized no-print'">Weiter</span>
  </div>
</template>

<style>

.flashcard .card.notFlipped > p {
  border: none;
}

.flashcard .card > .solution {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.flashcard .btn {
  position: fixed;
  bottom: 0;
  padding: 0.5em;
  margin: auto;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: bold;

  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

</style>

<script>
import {reactive} from "vue";

export default {
  name: 'FlashCardPart',
  props: ['part'],
  methods: {
    flipClicked() {
      this.flipped = true
      // console.log('#######', this)
    },
    nextClicked() {
      let nextIndex = this.index + 1
      if (nextIndex >= this.quiz.questions.length) {
        nextIndex = 0
      }
      this.index = nextIndex
      this.flipped = false
    }
  },
  async setup(props) {
    return {
      quiz: reactive(await props.part.getQuiz())
    }
  },
  data() {
    return {
      index: 0,
      flipped: false
    }
  }
}
</script>
