<template>
  <router-link class="card hover" v-for="(lesson, index) in lessons" :key="index" :to="lesson.to">
    <p :class="lesson.color">{{ lesson.title }} [{{ lesson.categoryTitle }}]</p>
    <p>{{ lesson.description }}</p>
  </router-link>
</template>

<script>
import { getInstance } from '../model/lessons'

export default {
  name: 'LessonsMenu',
  async setup() {
    return await getInstance();
  }
}
</script>
