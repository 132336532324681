import Helper from './helper'

export default class Question {
  constructor (quiz, questionData, idx, shouldShuffle) {
    this.quiz = quiz
    this.idx = idx
    this.title = questionData.title
    this.subtitle = questionData.subtitle
    this.type = questionData.type

    if (shouldShuffle !== undefined) {
      this.shouldShuffle = shouldShuffle
    } else {
      this.shouldShuffle = true
    }

    let idPrefix = this.type + 'q' + idx + 'a'
    let answers = null
    if (questionData.answers) {

      let answersTmp = questionData.answers
      answers = []
      for (let i = 0; i < answersTmp.length; i++) {
        answers.push({
          title: answersTmp[i].title,
          correct: answersTmp[i].correct || false,
          checked: false,
          id: idPrefix + i,
          index: i
        })
      }
    } else if (questionData.answer) {
      this.shouldShuffle = false
      answers = {
        text: questionData.answer,
        id: idPrefix
      }
    }
    this.answers = answers
    this.shuffle()

    this.active = true
    this.correct = false
  }

  shuffle() {
    if (this.shouldShuffle) {
      this.answers = Helper.shuffle(this.answers)

      for (let i = 0; i < this.answers.length; i++) {
        this.answers[i].index = i
      }
    }
  }

  check() {
    this.active = false
    this.correct = this['check' + this.type]()
    return {
      correct: this.correct
    }
  }

  // noinspection JSUnusedGlobalSymbols
  checkMC() {
    return this.answers.map((answer, index) => {
      return answer.checked === this.answers[index].correct
    }).reduce((previousValue, currentValue) => previousValue && currentValue)
  }

  // noinspection JSUnusedGlobalSymbols
  checkSC() {
    return this.answers[this.input] !== undefined && this.answers[this.input].correct
  }

  // noinspection JSUnusedGlobalSymbols
  checkText() {
    return this.input !== undefined && this.input === this.answers.text
  }

  encode() {
    return this['encode' + this.type]()
  }

  // noinspection JSUnusedGlobalSymbols
  encodeMC () {
    return this.answers.map((answer) => {
      return answer.checked
    })
  }

  // noinspection JSUnusedGlobalSymbols
  encodeSC () {
    return [this.checkSC()]
  }

  // noinspection JSUnusedGlobalSymbols
  encodeText () {
    return [this.checkText()]
  }

  restart () {
    this.active = true
    this.correct = false

    if (typeof this['restart' + this.type] === 'function') {
      this['restart' + this.type]()
    }

    this.shuffle()
  }

  restartMC () {
    for (let i = 0; i < this.answers.length; i++) {
      this.answers[i].checked = false
    }
  }

  restartSC () {
  }

  restartText () {
  }

  getSolutionText() {
    let solution = ''
    for (let i = 0; i < this.answers.length; i++) {
      if (!this.answers[i].correct) {
        continue
      }
      solution = solution + '<p>' + this.answers[i].title + '</p>'
    }
    return solution
  }
}
