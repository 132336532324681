<template>
  <div id="app">
    <header>
      <suspense>
        <template #default>
          <AppHeader />
        </template>
        <template #fallback>
          <span>&nbsp;</span>
        </template>
      </suspense>
    </header>
    <main>
      <router-view :key="$route.fullPath"></router-view>
    </main>
  </div>
</template>

<script>

import {defineAsyncComponent} from "vue";

export default {
  name: 'app',
  components: {
    AppHeader: defineAsyncComponent(() => import('./components/AppHeader'))
  },
  data: () => ({
  })
}
</script>

<style>
html, body, #app {
  margin: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

main, .main-sized {
  text-align: center;
  margin: auto;
  padding-left: 0.25em;
  padding-right: 0.25em;
  max-width: 800px;
}

main {
  margin-bottom: 5em;
}

.main-sized {
  display: block;
  width: calc(100% - 0.5em);
}

header {
  margin: 0;
  padding: 0;
  background-color: #cd000d;
  color: #ffffff;
}

header .title {
  display: inline-block;
  position: relative;
  font-size: 1.5em;
  line-height: 1;
  letter-spacing: .02em;
  font-weight: 400;
  box-sizing: border-box;
  padding-top: 16px;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 16px;
}

header .dot {
  display: inline-block;
  font-size: 1em;
  padding-right: 24px;
  line-height: 100%;
}

ul.menu {
  list-style: none;
  display: inline-block;
  padding: 0;
}

ul.menu li {
  padding: 5px 15px 5px 0;
  display: inline-block;
}

ul.menu li a, header .title a {
  text-decoration: none;
  color: #ffffff;
}

ul.menu li a:hover, header .title a:hover {
  color: #cccccc;
}

.disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.align-left {
  text-align: left;
}

/* Card-Styles */

.card {
  display: block;
  margin: auto auto 2em;
  padding: 0;

  text-decoration: none;
  color: #000000;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;

  border: 1px solid #555;
}

.card.hover:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.card > p, .card > ul {
  margin: 0;
  padding: 0.5em;
}

.card > p:first-child {
  border-bottom: 1px solid #555;
  font-weight: bold;
}

.card > p.subtitle {
  border-top: 1px solid #555;
  font-weight: bold;
}

.red {
  background-color: #cd000d !important;
  color: #ffffff;
}

.dark-red {
  background-color: #80000c !important;
  color: #ffffff;
}

.orange {
  background-color: #ffab01 !important;
  color: #000000;
}

.dark-orange {
  background-color: #f1671a !important;
  color: #ffffff;
}

.yellow {
  background-color: #E6EE5F !important;
  color: #000000;
}

.gray {
  background-color: #4f6d89 !important;
  color: #ffffff;
}

.dark-gray {
  background-color: #2c3e50 !important;
  color: #ffffff;
}

.blue {
  background-color: #164E95 !important;
  color: #ffffff;
}

.dark-blue {
  background-color: #112f5f !important;
  color: #ffffff;
}

.green {
  background-color: #16954E !important;
  color: #ffffff;
}

.dark-green {
  background-color: #115f2f !important;
  color: #ffffff;
}


@media print{
  #app {
    font-family: 'times new roman', times, serif;
  }

  .card {
    margin: auto auto 1em;
    box-shadow: none;
    page-break-inside: avoid;
  }

  .no-print {
    display: none;
  }
}

</style>
