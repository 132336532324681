<template>
  <div>
    <BreadCrumb :lesson="lesson" :part="part"/>
    <div class="card part-header">
      <p :class="color">{{ lesson.title }}</p>
      <p class="lesson-navigation">
        <router-link v-if="prev" :to="prev.to">Zurück</router-link>
        <span v-else class="disabled">Zurück</span>

        <span class="dot">·</span>
        <router-link :to="back">Übersicht</router-link>
        <span class="dot">·</span>

        <router-link v-if="next" :to="next.to">Weiter</router-link>
        <span v-else class="disabled">Weiter</span>
      </p>
    </div>

    <h1 class="part-title">
      {{ part.title }}
      <span v-if="type === 'Quiz' || type === 'FlashCard'" class="no-print"><br/>
        <router-link :to="'/slides/' + lessonName + '/' + partName" target="_blank" class="link-icon">
          <svg height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" stroke="none"
                      d="M501,28.2H266.4V11h-20.9v17.2H11v378.5h154.8l-51.7,82.7l17.5,11.7l59-94.3h131.1l59,94.2l17.5-11.7l-51.7-82.6H501V28.2    z M481.2,385.2H31.9V48.6h449.3V385.2z"/>
                <path fill="currentColor" stroke="none"
                      d="M221.6,107.8H71.5v219.3h150.1V107.8z M200.7,305.6H92.3V128.2h108.4V305.6z"/>
                <rect fill="currentColor" stroke="none" width="141.8" x="288.3" y="157.2" height="21.5"/>
                <rect fill="currentColor" stroke="none" width="141.8" x="288.3" y="256.2" height="21.5"/>
          </svg>
        </router-link>
      </span>
      <span v-if="type === 'Quiz'" class="no-print">
        <router-link :to="'/thema/' + lessonName + '/' + partName + '/FlashCard'" class="link-icon">
          <svg height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
            <path
                d="m15.6893661 13.7425356.5-2c.1339487-.5357949-.191812-1.0787294-.7276069-1.2126781-.0793206-.0198302-.1607738-.0298575-.2425356-.0298575h-13.43844719c-.55228475 0-1 .4477153-1 1 0 .0817618.01002735.163215.0298575.2425356l.5 2c.11129175.445167.51127485.7574644.9701425.7574644h12.43844719c.4588676 0 .8588507-.3122974.9701425-.7574644zm-.1893661-5.2425356.5606576-1.68197288c.1746478-.52394332-.1085122-1.09026329-.6324555-1.26491107-.1023789-.03412629-.2096068-.05145569-.3175235-.0513167l-13.22209729.01713315c-.55228428.00071562-.99941904.44901061-.99870428 1.0012949 0 .10774719.01769263.21476369.05197848.31691043l.55814449 1.66286217m14-5 .3059335-1.83560101c.0907951-.54477032-.2772246-1.05999786-.8219949-1.15079291-.0543315-.00905524-.1093182-.01360608-.164399-.01360608h-12.63907918c-.55228475 0-1 .44771525-1 1 0 .05508086.00455084.11006756.01360608.16439899l.3059335 1.83560101"
                fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                transform="translate(2 3)"/>
          </svg>
        </router-link>
      </span>
      <span v-if="type === 'FlashCard'" class="no-print">
        <router-link :to="'/thema/' + lessonName + '/' + partName + '/Quiz'" class="link-icon">
          <svg height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.023 294.023" xml:space="preserve">
            <path fill="currentColor" stroke="none" d="M124.916,0.002  c-1.649,0.045-3.169,0.9-4.064,2.285l-14.49,21.736h-49.35c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h50c2.761,0,5-2.239,5-5  V39.574l-10,15v19.449h-40v-40h37.682L85.631,55.117l-6.146-12.293c-1.205-2.485-4.196-3.523-6.681-2.318  c-2.485,1.205-3.523,4.196-2.318,6.681c0.018,0.036,0.035,0.072,0.054,0.108l10,20c1.235,2.47,4.238,3.472,6.709,2.237  c0.778-0.389,1.441-0.974,1.924-1.698l40-60c1.565-2.276,0.989-5.389-1.287-6.954C127.013,0.281,125.974-0.027,124.916,0.002  L124.916,0.002z M147.012,44.025c-2.761,0-5,2.239-5,5v10c0,2.761,2.239,5,5,5h90c2.761,0,5-2.239,5-5v-10c0-2.761-2.239-5-5-5  H147.012z M57.012,94.06c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h50c2.761,0,5-2.239,5-5v-50c0-2.761-2.239-5-5-5H57.012z   M62.012,104.06h40v40h-40V104.06z M147.012,114.023c-2.761,0-5,2.239-5,5v10c0,2.761,2.239,5,5,5h90c2.761,0,5-2.239,5-5v-10  c0-2.761-2.239-5-5-5H147.012z M57.012,164.023c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h50c2.761,0,5-2.239,5-5v-50  c0-2.761-2.239-5-5-5H57.012z M62.012,174.023h40v40h-40V174.023z M147.012,184.058c-2.761,0-5,2.239-5,5v10c0,2.761,2.239,5,5,5h90  c2.761,0,5-2.239,5-5v-10c0-2.761-2.239-5-5-5H147.012z M57.012,234.023c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h50  c2.761,0,5-2.239,5-5v-50c0-2.761-2.239-5-5-5L57.012,234.023L57.012,234.023z M62.012,244.023h40v40h-40V244.023z M147.012,254.023  c-2.761,0-5,2.239-5,5v10c0,2.761,2.239,5,5,5h90c2.761,0,5-2.239,5-5v-10c0-2.761-2.239-5-5-5H147.012z"/>
          </svg>
        </router-link>
      </span>
    </h1>
    <div v-if="error">
      {{ error }}
    </div>
    <Suspense v-else>
      <template #default>
        <component v-bind:is="type + 'Part'" :part="part"></component>
      </template>
      <template #fallback>
        <div>Lädt...</div>
      </template>
    </Suspense>
  </div>
</template>

<style scoped>
.card {
  margin-top: 1em;
}

.dot {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.link-icon {
  color: #888;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.link-icon:hover {
  color: #000;
}

@media print {
  .lesson-navigation {
    display: none;
  }

  .part-header {
    border: none;
  }

  .part-title {
    font-size: 1.5em;
  }
}
</style>

<script>
import {getInstance as getLessonInstance} from '../model/lessons'
import {onErrorCaptured, ref} from "vue";

async function fetchData(lessonName, partName) {
  const lesson = (await getLessonInstance()).getLesson(lessonName)
  const part = await lesson.getPart(partName)

  const next = part.getNext()
  const prev = part.getPrevious()

  return {
    lesson: lesson,
    part: part,
    color: lesson.color,
    next: next,
    prev: prev,
    back: lesson.to
  }
}

export default {
  name: 'LessonPart',
  props: ['lessonName', 'partName', 'type'],
  async setup(props) {
    const error = ref(null)
    onErrorCaptured(e => {
      error.value = e
      return true
    })

    let data = await fetchData(props.lessonName, props.partName)
    data.error = error
    return data
  }
}
</script>
