<template>
  <div v-if="error">
    {{ error }}
  </div>
  <Suspense v-else>
    <template #default>
      <LessonPart :lessonName="lessonName" :partName="partName" :type="type" />
    </template>
    <template #fallback>
      <div>Lädt...</div>
    </template>
  </Suspense>
</template>

<script>
import {onErrorCaptured, ref} from 'vue'

export default {
  name: 'LessonPartSuspense',
  props: ['lessonName', 'partName', 'type'],
  setup() {
    const error = ref(null)
    onErrorCaptured(e => {
      error.value = e
      return true
    })
    return {error}
  }
}
</script>
