import Lesson from './lesson'
import Helper from "./helper";

class Lessons {
  constructor (categories, lessons) {
    this.categories = categories
    this.lessons = lessons
    this.lessonsMap = {}
    for (let lesson of this.lessons) {
      this.lessonsMap[lesson.name] = lesson

      let category = this.categories[this.lessonsMap[lesson.name].category]
      this.lessonsMap[lesson.name].categoryTitle = category.title
      this.lessonsMap[lesson.name].color = category.color
    }
  }

  getLesson (lessonName) {
    return this.lessonsMap[lessonName]
  }

  getCategory (categoryName) {
    return this.categories[categoryName]
  }
}

let instance = null

async function getInstance() {
  if (instance) {
    return instance;
  }

  let json = await Helper.loadJson(`lessons/lessons.json`)
  let categories = json.categories
  let lessons = await Promise.all(json.lessons.map(async (lessonName) => {
    return Lesson.create(lessonName)
  }))

  instance = new Lessons(categories, lessons);
  return instance;
}

export {
  getInstance
}
