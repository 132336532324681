import Quiz from './quiz'
import PartTypes from './partTypes'
import Memory from './memory'
import Helper from './helper'

export default class Part {
  constructor (partName, lesson, partJson) {
    this.name = partName
    this.lesson = lesson

    this.title = partJson.title
    this.titleShort = partJson.titleShort || partJson.title
    this.description = partJson.description
    this.type = partJson.type
    this.format = partJson.format || 'json'
    this.color = partJson.color ? partJson.color : lesson.color
    this.category = this.lesson.category
    this.to = `${this.lesson.to}/${partName}/${this.type}`

    this.prev = null
    this.next = null
  }

  static async create(lesson, partName) {
    const partJson = await Helper.loadJson(`lessons/${lesson.name}/parts/${partName}/part.json`)
    return new Part(partName, lesson, partJson);
  }

  getNext () {
    return this.next
  }

  getPrevious () {
    return this.prev
  }

  async getContent () {
    if (this.type !== PartTypes.TEXT) {
      throw Error(`getContent not supported for type "${this.type}"`)
    }
    return await Helper.loadText(`lessons/${this.lesson.name}/parts/${this.name}/content.md`)
  }

  async getData () {
    if (this.type !== PartTypes.QUIZ && this.type !== PartTypes.MEMORY && this.type !== PartTypes.FLASHCARD) {
      throw Error(`getData not supported for type "${this.type}"`)
    }

    let data
    if (this.format === 'yaml') {
      data = await Helper.loadYaml(`lessons/${this.lesson.name}/parts/${this.name}/data.yaml`)
    } else {
      data = await Helper.loadJson(`lessons/${this.lesson.name}/parts/${this.name}/data.json`)
    }

    if (data.template !== undefined && data.questions !== undefined) {
      let indexMap = new Map()
      for (let i = 0; i < data.template.answers.length; i++) {
        indexMap.set(data.template.answers[i].title, i)
      }

      for (let i = 0; i < data.questions.length; i++) {
        let question = data.questions[i]

        question.type = question.type || data.template.type

        let answers = []
        for (let j = 0; j < question.answers.length; j++) {
          let answer = question.answers[j]
          answers[indexMap.get(answer.title)] = answer
        }

        for (let j = 0; j < data.template.answers.length; j++) {
          if (answers[j] !== undefined) {
            continue
          }
          answers[j] = {
            ...data.template.answers[j]
          }
        }
        question.answers = answers
      }
    }

    return data
  }

  async getQuiz () {
    if (this.type !== PartTypes.QUIZ && this.type !== PartTypes.FLASHCARD) {
      throw Error(`getQuiz not supported for type "${this.type}"`)
    }
    return Quiz.create(this)
  }

  async getMemory () {
    if (this.type !== PartTypes.MEMORY) {
      throw Error(`getMemory not supported for type "${this.type}"`)
    }
    return Memory.create(this)
  }

  async getSlides () {
    if (this.type !== PartTypes.QUIZ && this.type !== PartTypes.FLASHCARD) {
      throw Error(`getSlides not supported for type "${this.type}"`)
    }
    return await this.getData()
  }
}
